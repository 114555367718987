import React, { Suspense, lazy, useEffect, useState } from 'react'
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./pages/admin/Admin";
import Directory from './pages/admin/Directory';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

function App() {




    const Dashboard = lazy(() => import("./pages/admin/Dashboard"));
    const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ToastContainer />
      <Suspense>
      <Routes>
      <Route path="/admin" element={<Admin />}>
      <Route index element={<Dashboard />} />
         <Route path="dashboard" element={<Dashboard />} />
         <Route path="all-tasks" element={<Dashboard />} />
         <Route path="important-tasks" element={<Dashboard />} />
         <Route path="pending-tasks" element={<Dashboard />} />
         <Route path="completed-tasks" element={<Dashboard />} />
         <Route path="directory" element={<Directory />} />
    </Route>
      </Routes>
      </Suspense>
    </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
