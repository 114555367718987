import React from 'react'
import { FaPlus } from 'react-icons/fa';
import { FaRegTrashCan } from "react-icons/fa6";
import { inputField } from '../../helpers/Classes';
export default function Directory() {
    
    const directories = [
        {id:1,name:"main"},
        {id:2,name:"office"},
        {id:3,name:"home"}
    ]

  return (
    <div  className='flex  flex-col-reverse gap-5  sm:flex-col-reverse md:flex-row mx-3 '>
        <div className='bg-gray-200 dark:bg-primary p-2   rounded-md w-full '>
            <p className='text-xl font-bold p-2'>All Directories ({directories.length}) </p>
            {
                directories.length > 0 ?
                    directories.map((e:any,index:number)=>(
                        <>
                        <div className='bg-gray-100 dark:bg-gray-800 p-2 flex justify-between items-center my-3 rounded-xl'>
                            <p className='text-xl text-left'> {e.name}</p>
                            <div className='bg-gradient p-2 rounded-md'>
                            <FaRegTrashCan className='text-xl text-white hover:cursor-pointer  ' />

                            </div>
                        </div>
                        </>
                    ))
                :<p>Create New Directory</p>
            }
        </div>
        <div className='w-full sm:w-full md:w-[50%] '>
        <div className='bg-gray-200 dark:bg-secondary p-2   rounded-md w-full  '>
           <div className='flex flex-row items-center gap-2 p-1'>
            {/* <FaPlus /> */}
           <p className='text-xl '>Create New Directory</p>
           
           </div>
           <div className='p-2 mt-5'>
           <div className="flex flex-col mb-3">
                  <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Name <sup>*</sup></label>
                  <input type="text" className={`${inputField} `}  placeholder='name' required  />
                </div>
                <button className='bg-gray-100 shadow-md hover:shadow-xl rounded-lg my-2 text-white bg-gradient w-full p-2'>Add Directory</button>
           </div>
            </div>
        </div>  
    </div>
  )
}
