import React, { useState } from 'react'
import Models from './Models'
import { checkbox, inputField } from '../../helpers/Classes'
import { FaPlus  } from "react-icons/fa";
export default function AddTask(props:{
    isOpen:any;
    isClose:any
}) {
    const [modelStatus, setModelStatus] = useState(false)
  return (
    <Models name="Create New Task" isOpen={props.isOpen} isClose={props.isClose} >
       <div>
          <div>
              <div className='p-2 '>
                <div className="flex flex-col mb-3">
                  <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Title <sup>*</sup></label>
                  <input type="text" className={`${inputField} `}  placeholder='title' required  />
                </div>
                <div className="flex flex-col mb-3">
                  <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Date <sup>*</sup></label>
                  <input type="date" className={`${inputField} `}  placeholder='title' required  />
                </div>
                <div className="flex flex-col mb-3">
                  <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Select Directory <sup>*</sup></label>
                  <select className={`${inputField} `} required name="" id="">
                    <option value="">Select directory</option>
                  </select>
                </div>
                <div className="flex flex-col mb-3">
                  <div className='flex items-center justify-start gap-2 ml-2'>
                  <input type="checkbox" checked className={`${checkbox} `} name="" id="" /> Mark as important
                  </div>
                  <div className='flex items-center justify-start gap-2 ml-2'>
                  <input type="checkbox" checked className={`${checkbox} `} name="" id="" /> Mark as completed
                  </div>
                </div>
                  <button className='bg-gray-100 shadow-md hover:shadow-xl rounded-lg my-2 text-white bg-gradient w-full p-2'>Add Task</button>
              </div>
          </div>
       </div>
      </Models>
  )
}
