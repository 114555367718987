import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { IoCloseSharp } from "react-icons/io5";
export default function Models(props:{
    isOpen: any;
    isClose: any;
    children?:any;
    name:string;
}) {
    const [open, setOpen] = useState(props.isOpen ? props.isOpen : false);
    const cancelButtonRef:any = useRef()
    useEffect(()=>{
        setOpen(props.isOpen)
        const handleClickOutside = (event: any) => {
            if (cancelButtonRef.current && !cancelButtonRef.current.contains(event.target)) {
              // props.refClose ? props.isClode(false) : null
              props.isClose(false)
              props.isOpen(true)
              setOpen(false)
            }
          };
      
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
      
          // Unbind the event listener on component unmount
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
    },[props.isOpen, props.isClose, setOpen])
    const handleStatus = ()=>{
      props.isClose(false)
      setOpen(false);
    }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className={`bg-gray-200 text-dark dark:bg-dark-purple dark:text-white p-3 flex items-center justify-between `}>  
                <p className={`text-xl`}>
                {
                  props.name
                }
                </p>
                <div>
                <IoCloseSharp onClick={handleStatus} className={`text-xl hover:cursor-pointer `} />
                </div>
              </div>  
              <div className={`bg-gray-200 text-dark dark:bg-dark-purple dark:text-white p-2`}>
                {
                  props.children
                }
              </div>
             </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
